<!--
 * @Description: 练习试卷查看
 * @Author: liqin
 * @Date: 2022-02-15 15:29:11
-->
<template>
  <div>
    <!--  电脑  hidden-xs-only -->
    <el-row :gutter="10">
      <el-col :sm="24" class=" ">
        <div class="betweenCenter h3sy">
          {{ paper.papersName }}
        </div>

        <div class="positionr" v-if="isPreview && paper.isShowAchievement">
          <span>试卷得分：</span>
          <span> {{ UserInfo.userExaminationScore }}</span>
        </div>
        <el-card class="box-card">
          <!-- <div v-for="item in topicOutLineList.slice(OutLineList,OutLineList +1)" :key="item.topicOutlineId" class="text item"> -->
          <div
            v-for="item in topicOutLineList1"
            :key="item.topicOutlineId"
            class="text item"
          >
            <div class="topicOutlineName betweenJustify">
              <div class="mt10" style="font-weight: bold;">
                {{ item.topicOutlineName }}
              </div>
              <div>
                <span v-show="paper.papersShowPattern == 1"
                  >共
                  {{ topicOutLineList[OutLineList].questionBankList.length }}
                  题，当前第 {{ BankListNum + 1 }} 题，</span
                >
                <span> 共 {{ item.totalScore }} 分</span>
              </div>
            </div>
            <!-- <div v-for="val in item.questionBankList.slice(BankListNum,BankListNum +1)" :key="val.bankId"> -->
            <div v-for="val in item.questionBankList" :key="val.bankId">
              <!-- 单选题 -->
              <div v-if="val.bankType == 0">
                <bankTypeZero
                  ref="bankTypeZero"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                ></bankTypeZero>
              </div>
              <!-- 多选题 -->
              <div v-if="val.bankType == 1">
                <bankTypeOne
                  ref="bankTypeOne"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                ></bankTypeOne>
              </div>

              <!-- 简答题 -->
              <div v-if="val.bankType == 2">
                <bankTypeTwo
                  ref="bankTypeTwo"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                ></bankTypeTwo>
              </div>
              <!-- 不定项  -->
              <div v-if="val.bankType == 3">
                <bankTypeThree
                  ref="bankTypeThree"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                ></bankTypeThree>
              </div>
              <!-- 填空  -->
              <div v-if="val.bankType == 4">
                <bankTypeFour
                  ref="bankTypeFour"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                ></bankTypeFour>
              </div>
              <!-- 判断  -->
              <div v-if="val.bankType == 5">
                <bankTypeFive
                  ref="bankTypeFive"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                ></bankTypeFive>
              </div>
            </div>
          </div>

          <!-- <div class="betweenCenter alignCenter h100px">

          </div> -->
          <div class="betweenCenter alignCenter h100px">
            <el-button
              type="primary"
              v-show="this.paper.papersShowPattern == 1"
              @click="PreviousStep"
              >上一题</el-button
            >
            <el-button
              type="primary"
              v-show="this.paper.papersShowPattern == 1"
              @click="NextStep"
              >下一题</el-button
            >
            <el-button type="primary" v-show="topic" @click="$router.go(-1)">返回上一页</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import bankTypeZero from "./bankTypeZero.vue";
import bankTypeOne from "./bankTypeOne.vue";
import bankTypeTwo from "./bankTypeTwo.vue";
import bankTypeThree from "./bankTypeThree.vue";
import bankTypeFour from "./bankTypeFour.vue";
import bankTypeFive from "./bankTypeFive.vue";
export default {
  components: {
    bankTypeZero,
    bankTypeOne,
    bankTypeTwo,
    bankTypeThree,
    bankTypeFour,
    bankTypeFive,
  },
  data() {
    return {
      isAnswer: false, // 是否显示答案
      isView: false, // 是否是查看
      isScore: false, // 是否显示得分
      isPreview: true,
      topic: false, // 错题查看
      topicOutLineList: [],
      topicOutLineList1: [],
      paper: {},

      UserInfo: {},
      OutLineList: 0,
      BankListNum: 0,
     
    };
  },
  mounted() {
    this.findExaminationUserPapersApi()
    this.topic = true
    if ("isSubmitExam" in this.$route.query) {
      // 试卷点击开始考试
      this.isScore = this.$route.query.isSubmitExam == "true";
      this.isAnswer = this.$route.query.isSubmitExam == "true";
    }
    if ("isView" in this.$route.query) {
      // 查询学员考试试题信息
      this.findExaminationUserPapersApi();
    }
    if ("preview" in this.$route.query) {
      this.isView = true;
      this.isPreview = false;
      this.findExaminationUserPapersApi()
    }
  },
  methods: {   
    findExaminationUserPapersApi() {
      
        const param = {
          userPagersId: this.$route.query.userPapersId
        }
        // let _isError = this.$route.query?.isError??0
        // if(1 == _isError){
        //   param.userOptionIsok = 0
        // }
      this.$api
        .findExaminationUserPapersApi(param)
        .then((res) => {
          this.isView = true;
          this.topicOutLineList = res.data.topicOutLineList || [];
          this.topicOutLineList1 = res.data.topicOutLineList || [];
          this.paper = res.data.examinationPapers || {};
          this.UserInfo = res.data.examinationUserPapers || {};
          if (res.data.examinationPapers.papersShowPattern == 1) {
            this.isPapersShowPattern();
          }
        });
    },
    // 琢题模式 ：一页显示一道试题
    isPapersShowPattern() {
      // this.topicOutLineLists = this.topicOutLineList.slice(this.OutLineList, this.OutLineList + 1)
      // console.log(this.topicOutLineLists);
      // // console.log( this.topicOutLineLists[0].questionBankList.slice(this.BankListNum, this.BankListNum + 1) );
      // this.topicOutLineLists[0].questionBankList = this.topicOutLineLists[0].questionBankList.slice(this.BankListNum, this.BankListNum + 1)
      // // .questionBankList.slice(this.BankListNum, this.BankListNum + 1) || [];
      const arr = JSON.parse(JSON.stringify(this.topicOutLineList));
      this.topicOutLineList1 = arr
        .slice(this.OutLineList, this.OutLineList + 1)
        .map((item) => {
          item.questionBankList = item.questionBankList.slice(
            this.BankListNum,
            this.BankListNum + 1
          );
          return item;
        });
    },

    // 上一题
    PreviousStep() {
      console.log(
        this.BankListNum,
        this.topicOutLineList[this.OutLineList].questionBankList.length
      );
      if (this.BankListNum == 0 && this.OutLineList > 0) {
        console.log(this.OutLineList, "OutLineList");
        this.OutLineList--;
        this.BankListNum = this.topicOutLineList[
          this.OutLineList
        ].questionBankList.length;
      }

      if (this.BankListNum > 0) {
        this.BankListNum--;
      }
      this.isPapersShowPattern();
    },
    // 下一题
    NextStep() {
      if (
        this.BankListNum ==
          this.topicOutLineList[this.OutLineList].questionBankList.length - 1 &&
        this.OutLineList < this.topicOutLineList.length - 1
      ) {
        console.log(this.OutLineList, "OutLineList");
        this.OutLineList++;
        this.BankListNum = -1;
      }
      console.log(
        this.BankListNum,
        this.topicOutLineList[this.OutLineList].questionBankList.length
      );
      if (
        this.BankListNum <
        this.topicOutLineList[this.OutLineList].questionBankList.length - 1
      ) {
        this.BankListNum++;
      }
      this.isPapersShowPattern();
    },

    examTimesEnd() {},
  },
};
</script>

<style lang="scss" scoped>
.h3sy {
  margin: 0;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #18cca7;
  color: #fff;
}
::v-deep .el-card {
  margin: 0px 4%;
}

.dian {
  /* font-size: 20px; */
  margin-right: 5px;
  display: inline-block;
  width: 3px;
  height: 3px;
  background: #3ce4bc;
  position: relative;
  top: -5px;
  color: #3ce4bc;
}
.topicOutlineName {
  font-size: 18px;
  margin-top: 20px;
}
.h100px {
  height: 100px;
}
.positionr {
  position: absolute;
  top: 16px;
  right: 7%;
  // font-size: 20px;
  color: #fff;
}
.wronglist{
  width: 100%;
  height: 4.5vh;
  margin-bottom: 10px;
  background:#fff;
}
.record{
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
}
//pc端
@media only screen and (min-width: 1200px) {
 .positionr {
    font-size: 20px;
  }
  .h3sy{
    font-size: 20px;
  }
}
//pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  .positionr {
    font-size: 20px;
  }
  .h3sy{
    font-size: 20px;
  }
}
// 手机端
@media only screen and (max-width: 767px) {
  .positionr {
    font-size: 15px;
  }
  .h3sy{
    font-size: 15px;
  }
}
</style>
